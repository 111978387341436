import { type TggFlagsObject, createApiService } from '@tgg/util';

export type PostOptimizelyFlagsRequestBody = {
    overrideUserId?: string;
    pathname: string;
};

export const optimizelyFlagsService = createApiService<
    'post',
    TggFlagsObject,
    PostOptimizelyFlagsRequestBody
>('post', {
    path: '/api/proxy/optimizely/flags',
    unCapitalizeProperties: true,
});
