import { BasketItemDto, ShoppingBasketDto } from '../../join.types';
import {
    PaymentItemsWithTotal,
    PaymentCategory,
    PaymentItem,
    Nullable,
    SteppedKickerPaymentItems,
} from '@tgg/common-types';
import {
    getYourPaymentDiscountMessage,
    getWasPrice,
    nullOrCastToNumber,
} from '@tgg/util';

interface CreatePaymentInfoArguments {
    shoppingBasketDto: ShoppingBasketDto;
    productItem?: BasketItemDto;
    addOnItems?: BasketItemDto[];
}

export const mapTodayPaymentDetails = ({
    shoppingBasketDto,
    productItem,
    addOnItems,
}: CreatePaymentInfoArguments): PaymentItemsWithTotal => {
    const {
        joiningFee,
        originalJoiningFee,
        todaysPaymentTotal,
        isSteppedKickerPromo,
    } = shoppingBasketDto;
    const paymentItems: PaymentItem[] = [];

    if (productItem) {
        const {
            discountDuration,
            discountedPrice,
            regularPrice,
            productPaymentFrequency,
            description,
        } = productItem;

        const cost = discountedPrice ?? regularPrice;

        const category: PaymentCategory =
            productPaymentFrequency === 'Monthly'
                ? 'Monthly membership Fee'
                : 'One Off';

        const discountMessage =
            discountedPrice && !isSteppedKickerPromo
                ? getYourPaymentDiscountMessage(discountDuration, false)
                : null;

        paymentItems.push({
            label: description,
            cost,
            discountMessage,
            originalPrice: discountedPrice !== null ? regularPrice : null,
            category,
        });
    }

    const addOnsPaymentItems = createTodayAddonsList(addOnItems);

    paymentItems.push(...addOnsPaymentItems);

    if (joiningFee || typeof originalJoiningFee === 'number') {
        const cost =
            nullOrCastToNumber(joiningFee ?? originalJoiningFee) ??
            /* istanbul ignore next */ 0;

        const originalPrice = nullOrCastToNumber(
            typeof joiningFee === 'number' ? originalJoiningFee : null,
        );

        paymentItems.push({
            label: 'Joining fee',
            cost,
            originalPrice: getWasPrice({
                nowPrice: cost,
                originalPrice,
            }),
            discountMessage: null,
            category: 'Joining Fee',
        });
    }

    return {
        items: paymentItems,
        total: todaysPaymentTotal ?? 0,
    };
};

const createTodayAddonsList = (addOnItems?: BasketItemDto[]): PaymentItem[] => {
    let paymentItems: PaymentItem[] = [];

    if (!addOnItems || addOnItems.length === 0) {
        return paymentItems;
    }

    const filteredAddons = addOnItems.filter(
        item => item.description && item.regularPrice,
    );

    paymentItems = filteredAddons.map(
        ({ description, regularPrice, discountedPrice }) => ({
            label: description,
            cost: discountedPrice ?? regularPrice,
            originalPrice: discountedPrice !== null ? regularPrice : null,
            category: 'Add Ons',
        }),
    );

    return paymentItems;
};

export const mapMonthlyPaymentDetails = ({
    shoppingBasketDto,
    productItem,
    addOnItems,
}: CreatePaymentInfoArguments): PaymentItemsWithTotal => {
    const { subsequentPaymentsTotal } = shoppingBasketDto;
    const paymentItems: PaymentItem[] = [];

    if (productItem?.regularPrice) {
        const {
            description,
            regularPrice,
            discountDuration,
            discountedSubsequentMonthlyPrice,
        } = productItem;

        const originalPrice = discountedSubsequentMonthlyPrice
            ? regularPrice
            : null;

        const discountMessage = originalPrice
            ? getYourPaymentDiscountMessage(discountDuration)
            : null;

        paymentItems.push({
            label: description,
            cost: discountedSubsequentMonthlyPrice ?? regularPrice,
            originalPrice,
            discountMessage,
            category: 'Monthly membership Fee',
        });
    }

    if (addOnItems && addOnItems.length > 0) {
        const filteredAddons = addOnItems.filter(
            item => item.description && item.regularPrice,
        );

        const addOnsPaymentItems = filteredAddons.map(
            ({ description, regularPrice }) => ({
                label: description,
                cost: regularPrice,
                category: 'Add Ons',
            }),
        ) as PaymentItem[];

        paymentItems.push(...addOnsPaymentItems);
    }

    return {
        items: paymentItems,
        total: subsequentPaymentsTotal,
    };
};

// Common function to transform basket items to payment items
export const mapBasketItemsToPaymentItems = (items: BasketItemDto[]) =>
    items.map(element => createPaymentItem(element));

// Helper function to create payment item from basket item
export const createPaymentItem = (basketItem: BasketItemDto) => ({
    label: basketItem.description || '',
    cost:
        basketItem.discountedSubsequentMonthlyPrice ?? basketItem.regularPrice,
    originalPrice:
        basketItem.discountedSubsequentMonthlyPrice != null
            ? basketItem.regularPrice
            : null,
    discountMessage: getYourPaymentDiscountMessage(basketItem.discountDuration),
});

// Type guard to check if promotionPrices is not null or undefined - to resolve TS issues
function hasPromotionPrices(item: BasketItemDto): item is BasketItemDto & {
    promotionPrices: NonNullable<BasketItemDto['promotionPrices']>;
} {
    return !!item.promotionPrices && item.promotionPrices.length > 0;
}

export const mapSteppedKickerTruncatedForCookie = (
    monthlyBasketItems: BasketItemDto[],
    membershipStartDate: string,
): Nullable<SteppedKickerPaymentItems> => {
    const steppedKickerMonthlyItem = monthlyBasketItems.find(monthlyItem =>
        hasPromotionPrices(monthlyItem),
    );

    if (
        !steppedKickerMonthlyItem ||
        !hasPromotionPrices(steppedKickerMonthlyItem)
    ) {
        return null;
    }

    const remainingMonthlyBasketItems = monthlyBasketItems.filter(
        item => !hasPromotionPrices(item),
    );

    const remainingMonthlyPaymentItems = mapBasketItemsToPaymentItems(
        remainingMonthlyBasketItems,
    );

    const { regularPrice, promotionPrices, description } =
        steppedKickerMonthlyItem;

    // Need to add final stage where payments revert to original regular monthly price
    const steppedKickerPromotionStages = [
        // Need to skip the first element since it's already in today's payment
        ...promotionPrices.slice(1).map(({ promotionPrice }) => promotionPrice),
        regularPrice,
    ];

    return {
        promotionPrices: steppedKickerPromotionStages,
        addOns: remainingMonthlyPaymentItems,
        productOriginalPrice: regularPrice,
        productDescription: description,
        membershipStartDate,
    };
};
