import { SeverityLevel } from '@microsoft/applicationinsights-web';

import {
    LogMessageData,
    type TggTrace,
    type TggTraceCorrelationParameters,
} from '../logger.types';
import { createTraceMessage } from '../utils/loggerTraceUtils';

const clientLogger = (
    trace: TggTrace,
    parameters: TggTraceCorrelationParameters,
) => {
    if (typeof window !== 'undefined' && window.appInsights) {
        const { appInsights } = window;
        appInsights.trackTrace(trace, parameters);
    }
};

export const logClientMessage = (options: LogMessageData) => {
    const { applicationIdentifier, message, parameters, severityLevel } =
        options;

    const trace = createTraceMessage(
        message,
        severityLevel === 'debug' ? SeverityLevel.Information : severityLevel,
        parameters,
        {
            applicationIdentifier,
            type: 'CLIENT',
        },
    );
    clientLogger(trace, parameters);
};
