import { Nullable } from './utility.types';

export interface PaymentItemsWithCost {
    paymentItems: PaymentItem[];
    totalCost: number;
}

export enum PaymentType {
    DirectDebit = 'DD',
    PayPalDirectDebit = 'PAYPAL_DD',
    CardDirectDebit = 'CARD_DD',
    CC = 'CC',
    Card = 'CARD',
    CardOneOff = 'ONEOFFCARD',
    CreditCard = 'Card Payment',
}

export enum PaymentCategories {
    AddOns = 'Add Ons',
    MonthlyMembership = 'Monthly membership Fee',
    JoiningFee = 'Joining Fee',
    OneOff = 'One Off',
}

export type PaymentCategory = `${PaymentCategories}`;

export type PaymentLogData = {
    userId: string;
    paymentId: string;
    journeyInfo?: {
        journeyName: string;
        journeyStep?: string;
    };
};

export interface BasketPaymentSummary {
    today?: Nullable<PaymentItemsWithTotal>;
    monthly?: Nullable<PaymentItemsWithTotal>;
    steppedKicker?: Nullable<SteppedKickerPaymentItems>;
    revenue?: Nullable<number>;
}

export interface SteppedKickerPaymentItems {
    promotionPrices: number[];
    addOns: PaymentItem[];
    productOriginalPrice: number;
    productDescription: string;
    membershipStartDate: string;
}
export interface PaymentItemsWithTotal {
    items: PaymentItem[];
    total?: Nullable<number>;
    totalText?: string;
}

export interface PaymentItem {
    label: string;
    itemHasLineThrough?: boolean;
    cost: number;
    originalPrice?: number | null;
    discountDuration?: number;
    discountMessage?: string | null;
    category?: PaymentCategory;
}
