import {
    MainMenuNavigationLink,
    MenuItemsType,
    PromotionalLinks,
} from '../../components/SideNavigation/SideNavigation.types';
import { palette } from '../../theme';

const promotionalLinks: PromotionalLinks = [
    {
        promotionalLinkHref: '/forgotten-pin/',
        promotionalLinkText: 'Forgotten PIN?',
        promotionalVILinkText: 'forgotten PIN?',
        caseSensitive: true,
    },
    {
        chipColor: palette.primary.main,
        chipText: 'Free',
        promotionalLinkHref: '/the-gym-group-app/',
        promotionalLinkText: 'The Gym Group App',
    },
    {
        promotionalLinkHref: '/student-membership/',
        promotionalLinkText: 'Student Membership',
    },
    {
        promotionalLinkHref: '/gym-membership-deals-and-offers/',
        promotionalLinkText: 'Membership deals and offers',
    },
];

const kioskPromotionalLinks: PromotionalLinks = [
    {
        promotionalLinkHref: '/forgotten-pin/',
        promotionalLinkText: 'Forgotten PIN?',
        promotionalVILinkText: 'forgotten PIN?',
        caseSensitive: true,
    },
    {
        promotionalLinkHref: '?gym-tour-overlay',
        promotionalLinkText: 'Gym Tour',
        caseSensitive: true,
    },
];

const gymsList = [
    {
        text: 'Birmingham Gyms',
        href: '/find-a-gym/birmingham-gyms/',
    },
    {
        text: 'Brighton Gyms',
        href: '/find-a-gym/brighton-gyms/',
    },
    {
        text: 'Bristol Gyms',
        href: '/find-a-gym/bristol-gyms/',
    },
    {
        text: 'Cardiff Gyms',
        href: '/find-a-gym/cardiff-gyms/',
    },
    {
        text: 'East London Gyms',
        href: '/find-a-gym/east-london-gyms/',
    },

    {
        text: 'West London Gyms',
        href: '/find-a-gym/west-london-gyms/',
    },
    {
        text: 'Edinburgh Gyms',
        href: '/find-a-gym/edinburgh-gyms/',
    },

    {
        text: 'Glasgow Gyms',
        href: '/find-a-gym/glasgow-gyms/',
    },
    {
        text: 'Leeds Gyms',
        href: '/find-a-gym/leeds-gyms/',
    },
    {
        text: 'Leicester Gyms',
        href: '/find-a-gym/leicester-gyms/',
    },
    {
        text: 'Liverpool Gyms',
        href: '/find-a-gym/liverpool-gyms/',
    },
    {
        text: 'London Gyms',
        href: '/find-a-gym/london-gyms/',
    },
    {
        text: 'Manchester Gyms',
        href: '/find-a-gym/manchester-gyms/',
    },
    {
        text: 'Newcastle Gyms',
        href: '/find-a-gym/newcastle-gyms/',
    },
    {
        text: 'North London Gyms',
        href: '/find-a-gym/north-london-gyms/',
    },
    {
        text: 'Northampton Gyms',
        href: '/find-a-gym/northampton-gyms/',
    },
    {
        text: 'Norwich Gyms',
        href: '/find-a-gym/norwich-gyms/',
    },
    {
        text: 'Nottingham Gyms',
        href: '/find-a-gym/nottingham-gyms/',
    },
    {
        text: 'Oxford Gyms',
        href: '/find-a-gym/oxford-gyms/',
    },
    {
        text: 'Plymouth Gyms',
        href: '/find-a-gym/plymouth-gyms/',
    },
    {
        text: 'Reading Gyms',
        href: '/find-a-gym/reading-gyms/',
    },
    {
        text: 'Sheffield Gyms',
        href: '/find-a-gym/sheffield-gyms/',
    },
    {
        text: 'South London Gyms',
        href: '/find-a-gym/south-london-gyms/',
    },
    {
        text: 'Southampton Gyms',
        href: '/find-a-gym/southampton-gyms/',
    },
];

const mainMenuForLoggedOutUsers: MainMenuNavigationLink[] = [
    {
        text: 'Find a Gym?',
        leftIconName: 'gymFinder',
    },

    {
        text: 'Classes',
        leftIconName: 'classes',
        href: '/gym-classes/',
    },

    {
        text: 'Exercise Hub',
        leftIconName: 'workouts',
        href: '/exercises/',
    },

    {
        text: 'Personal Training',
        leftIconName: 'personalTrainers',
        href: '/personal-training/',
    },

    {
        text: 'Opening Hours',
        leftIconName: 'openingHours',
        href: '/gym-opening-times/',
    },

    {
        text: 'help & support',
        leftIconName: 'question',
        href: 'https://support.thegymgroup.com',
    },

    {
        text: 'FAQs',
        caseSensitive: true,
        leftIconName: 'faqs',
        href: '/faqs/',
    },

    {
        text: 'Careers',
        leftIconName: 'careers',
        href: '/careers/',
    },

    {
        text: 'blog',
        leftIconName: 'blog',
        href: '/blog/',
    },
];

const mainMenuForLoggedInUsers: MainMenuNavigationLink[] = [
    {
        text: 'Find a Gym?',
        leftIconName: 'gymFinder',
    },

    {
        text: 'Personal Trainers',
        leftIconName: 'personalTrainers',
        href: '/personal-training/',
    },

    {
        text: 'Classes',
        leftIconName: 'classes',
        href: '/gym-classes/',
    },

    {
        text: 'Opening Hours',
        leftIconName: 'openingHours',
        href: '/gym-opening-times/',
    },

    {
        text: 'help & support',
        leftIconName: 'question',
        href: 'https://support.thegymgroup.com',
    },

    {
        text: 'FAQs',
        leftIconName: 'faqs',
        href: '/faqs/',
        caseSensitive: true,
    },

    {
        text: 'blog',
        leftIconName: 'blog',
        href: '/blog/',
    },
];

const kioskMainMenu: MainMenuNavigationLink[] = [
    {
        text: 'Find a Gym?',
        leftIconName: 'gymFinder',
    },

    {
        text: 'Classes',
        leftIconName: 'classes',
        href: '/gym-classes/',
    },

    {
        text: 'Opening Hours',
        leftIconName: 'openingHours',
        href: '/gym-opening-times/',
    },

    {
        text: 'help & support',
        leftIconName: 'question',
        href: 'https://support.thegymgroup.com',
    },

    {
        text: 'FAQs',
        caseSensitive: true,
        leftIconName: 'faqs',
        href: '/faqs/',
    },

    {
        text: 'Careers',
        leftIconName: 'careers',
        href: '/careers/',
    },
];

/**
 * menu items displayed to ALL users that are not logged in.
 * used in commerce application.
 * also used in member application as fallback nav items.
 */
export const commonMenuItems: MenuItemsType = {
    promotionalLinks,
    mainMenu: mainMenuForLoggedOutUsers,
    gymsList,
};

/**
 * menu items displayed to ALL logged in member (i.e. regardless of what permissions a user has)
 */
export const loggedInMemberNavigationItems: MenuItemsType = {
    promotionalLinks,
    mainMenu: mainMenuForLoggedInUsers,
    gymsList,
};

/**
 * menu items displayed on the .kiosk. subdomain
 */
export const kioskNavigationItems: MenuItemsType = {
    promotionalLinks: kioskPromotionalLinks,
    mainMenu: kioskMainMenu,
    gymsList,
};
