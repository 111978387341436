import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import {
    AVAILABLE_COOKIES,
    AnyContext,
    getCookieSsr,
    getUserIdentifierCookieSsr,
    setUserIdentifierCookieSsr,
    userIdentifierCookieApi,
} from '../../cookies';
import { getCookie } from '../../cookies/cookies.browser.helpers';
import { FlagsConstants } from '../featureFlags.types';
import { type Nullable } from '@tgg/common-types';

const {
    publicRuntimeConfig: { APP_ENVIRONMENT },
} = getConfig();

export const resolveUserCorrlationId = (ids: {
    gaId: Nullable<string>;
    optiId: Nullable<string>;
    fallbackId: Nullable<string>;
}) => {
    const { fallbackId, gaId, optiId } = ids;

    if (fallbackId && fallbackId.length > 0) return fallbackId;

    const resolvedId =
        gaId && gaId.length > 0
            ? gaId
            : optiId && optiId.length > 0
            ? optiId
            : (uuidv4() as string);

    return resolvedId;
};

export const getOptimizelyUserId = (context: AnyContext) => {
    try {
        const gaUserIdFromCookie = getCookieSsr<string>({
            context,
            cookieKey: AVAILABLE_COOKIES.GA4_USER_ID,
        });

        const optiUserIdFromCookie = getCookieSsr<string>({
            context,
            cookieKey: AVAILABLE_COOKIES.OPTIMIZELY_USER_ID,
        });

        const fallbackUserIdFromCookie = getUserIdentifierCookieSsr(context);

        const resolvedId = resolveUserCorrlationId({
            fallbackId: fallbackUserIdFromCookie,
            gaId: gaUserIdFromCookie,
            optiId: optiUserIdFromCookie,
        });

        if (
            !fallbackUserIdFromCookie ||
            fallbackUserIdFromCookie !== resolvedId
        )
            setUserIdentifierCookieSsr(context, resolvedId as string);

        return { resolvedId, error: null };
    } catch (error) {
        return { resolvedId: FlagsConstants.UNRESOLVED, error };
    }
};

export const getOptimizelyUserIdClientSide = (options?: {
    shouldReset?: boolean;
}) => {
    try {
        const gaUserIdFromCookie = getCookie<string>({
            cookieKey: AVAILABLE_COOKIES.GA4_USER_ID,
        });

        const optiUserIdFromCookie = getCookie<string>({
            cookieKey: AVAILABLE_COOKIES.OPTIMIZELY_USER_ID,
        });

        const fallbackUserIdFromCookie =
            userIdentifierCookieApi.browser.getCookie();

        const resolvedId = resolveUserCorrlationId({
            fallbackId: fallbackUserIdFromCookie,
            gaId: gaUserIdFromCookie,
            optiId: optiUserIdFromCookie,
        });

        if (
            options?.shouldReset &&
            (!fallbackUserIdFromCookie ||
                /* istanbul ignore next */ fallbackUserIdFromCookie !==
                    resolvedId)
        )
            userIdentifierCookieApi.browser.setCookie(resolvedId);

        return { resolvedId, error: null };
    } catch (error) {
        return { resolvedId: FlagsConstants.UNRESOLVED, error };
    }
};
