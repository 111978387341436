import { z } from 'zod';

import {
    BasketItems,
    BasketPayments,
    DayPassValidation,
    GymBagShoppingBasketCookie,
    JoinJourneyShoppingBasketCookie,
    PaymentCategories,
    PaymentItemsWithTotal,
    SteppedKickerPaymentItems,
    TggMembershipType,
} from '@tgg/common-types';

const dayPassRefine = (schema: DayPassValidation, context: z.RefinementCtx) => {
    if (schema.isDayPass && !schema.membershipStartDate) {
        context.addIssue({
            code: 'custom',
            path: ['membershipStartDate'],
            message:
                'membershipStartDate must be a string when isDayPass is true',
        });
    }
};

export const basketItemsSchema: z.ZodType<BasketItems> = z.object({
    product: z.object({
        id: z.string(),
        description: z.string(),
        membershipType: z.nativeEnum(TggMembershipType),
        expectedPaymentFrequency: z.enum(['OneOff', 'Monthly']),
    }),
    addOnIds: z.array(z.string()),
});

const paymentItemsWIthTotalSchema: z.ZodType<PaymentItemsWithTotal> = z.object({
    items: z.array(z.any()),
    total: z.number().nullable().optional(),
    totalText: z.string().optional(),
});

const steppedKickerPaymentItemsSchema: z.ZodType<SteppedKickerPaymentItems> =
    z.object({
        promotionPrices: z.array(z.number()),
        addOns: z.array(
            z.object({
                label: z.string(),
                cost: z.number(),
                itemHasLineThrough: z.boolean().optional(),
                originalPrice: z.number().nullable().optional(),
                discountDuration: z.number().optional(),
                discountMessage: z.string().nullable().optional(),
                category: z.nativeEnum(PaymentCategories).optional(),
            }),
        ),
        productOriginalPrice: z.number(),
        productDescription: z.string(),
        membershipStartDate: z.string(),
    });

const commonPaymentSummary = {
    wref: z.string().optional(),
    revenue: z.number().nullable().optional(),
    promoCode: z.string().optional(),
    isReoccurringCreditCard: z.boolean().optional(),
    today: paymentItemsWIthTotalSchema.nullable().optional(),
    monthly: paymentItemsWIthTotalSchema.nullable().optional(),
    steppedKicker: steppedKickerPaymentItemsSchema.nullable().optional(),
};

export const basketPaymentsSchema: z.ZodType<BasketPayments> = z.object({
    ...commonPaymentSummary,
});

const gymBagPaymentsSchema: z.ZodType<
    Omit<BasketPayments, 'today'> & Required<Pick<BasketPayments, 'today'>>
> = z.object({
    ...commonPaymentSummary,
    today: paymentItemsWIthTotalSchema,
});

export const joinJourneyShoppingBasketSchema: z.ZodType<
    JoinJourneyShoppingBasketCookie & DayPassValidation
> = z
    .object({
        isDayPass: z.boolean(),
        branchId: z.string(),
        basketItems: basketItemsSchema,
        payments: basketPaymentsSchema,
        membershipStartDate: z.string().optional(),
    })
    .superRefine(dayPassRefine);

export const gymBagShoppingBasketSchema: z.ZodType<
    GymBagShoppingBasketCookie & DayPassValidation
> = z
    .object({
        isDayPass: z.boolean(),
        branchId: z.string(),
        basketItems: basketItemsSchema,
        payments: gymBagPaymentsSchema,
        membershipStartDate: z.string().optional(),
    })
    .superRefine(dayPassRefine);
