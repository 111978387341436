import { ShoppingBasketDto } from '../../join.types';
import {
    mapMonthlyPaymentDetails,
    mapSteppedKickerTruncatedForCookie,
    mapTodayPaymentDetails,
} from './paymentSummary.helpers';
import {
    BasketPaymentSummary,
    TggMembershipType,
    TggAddonMembershipType,
} from '@tgg/common-types';

export const mapPaymentSummary = (
    shoppingBasketDto: ShoppingBasketDto,
): BasketPaymentSummary => {
    const { basketItems, isSteppedKickerPromo, membershipStartDate } =
        shoppingBasketDto;

    const productItem = basketItems.find(({ tggMembershipType }) =>
        Object.values(TggMembershipType).includes(
            tggMembershipType as TggMembershipType,
        ),
    );
    const addOnItems = basketItems.filter(({ tggMembershipType }) =>
        Object.values(TggAddonMembershipType).includes(
            tggMembershipType as TggAddonMembershipType,
        ),
    );

    const monthlyBasketItems = basketItems.filter(basketItem => {
        return basketItem.productPaymentFrequency === 'Monthly';
    });

    const monthly =
        !isSteppedKickerPromo &&
        productItem?.productPaymentFrequency === 'Monthly'
            ? mapMonthlyPaymentDetails({
                  shoppingBasketDto,
                  productItem,
                  addOnItems,
              })
            : null;

    const steppedKicker = isSteppedKickerPromo
        ? mapSteppedKickerTruncatedForCookie(
              monthlyBasketItems,
              membershipStartDate,
          )
        : null;

    const today = mapTodayPaymentDetails({
        shoppingBasketDto,
        productItem,
        addOnItems,
    });

    const revenue = today?.total;

    return {
        today,
        monthly,
        steppedKicker,
        revenue,
    };
};
