import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { LogMessageData } from '../logger.types';
import { createTraceMessage } from './loggerTraceUtils';

export type BaseLogger = {
    error: (message: string, ...rest: any) => void;
    info: (message: string, ...rest: any) => void;
    warn: (message: string, ...rest: any) => void;
    log: (message: string, ...rest: any) => void;
    debug?: (message: string, ...rest: any) => void;
    verbose?: (message: string, ...rest: any) => void;
    crit?: (message: string, ...rest: any) => void;
    critical?: (message: string, ...rest: any) => void;
};

export const getLogMethodBySeverity = <T extends BaseLogger>(
    severityLevel: SeverityLevel | 'debug',
    logger: T,
) => {
    switch (severityLevel) {
        case SeverityLevel.Critical:
            return logger.crit ?? logger.critical ?? logger.error;
        case SeverityLevel.Error:
            return logger.error;
        case SeverityLevel.Information:
            return logger.info;
        case SeverityLevel.Verbose:
            return logger.verbose ?? logger.log;
        case SeverityLevel.Warning:
            return logger.warn;
        case 'debug':
            return logger.debug ?? logger.info;
        /* istanbul ignore next */
        default:
            return logger.log;
    }
};

export const getLoggerType = () =>
    typeof window !== 'undefined' ? 'CLIENT' : 'SERVER';

export const createLogMessageCaller =
    <T extends BaseLogger>(logger: T) =>
    // eslint-disable-next-line unicorn/consistent-function-scoping
    (options: LogMessageData) => {
        const { applicationIdentifier, message, parameters, severityLevel } =
            options;

        const trace = createTraceMessage(
            message,
            severityLevel === 'debug'
                ? SeverityLevel.Information
                : severityLevel,
            parameters,
            {
                applicationIdentifier,
                type: getLoggerType(),
            },
        );

        const logMethod = getLogMethodBySeverity(severityLevel, logger);
        logMethod(trace.message, { ...parameters, ...trace.properties });
    };
