/* eslint-disable @typescript-eslint/no-floating-promises */
import { OptimizelyDecision } from '@optimizely/optimizely-sdk';
import { ReactSDKClient } from '@optimizely/react-sdk';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { useAppConfigContext } from '../../appConfigContext';
import {
    attachDecisionEventListeners,
    initOptimizelyFeClient,
} from '../optimizelyFeClient/initOptimizelyFeClient';
import { OptimizelyFeatureFlags } from '@tgg/common-types';
import {
    CustomOptimizelyEventAttributes,
    OptimizelyEvent,
    TggFlagsObject,
    TggTraceCorrelationParameters,
    defaultFlags,
    mapOptimizelyFlags,
} from '@tgg/util';

export type OptimizelyClientHookConfig = {
    sdkKey: string;
    resolvedUserId: string;
    pathname: string;
    useClient: boolean;
    isUserInitialized: boolean;
    isRendered: boolean;
    setFlags: Dispatch<SetStateAction<TggFlagsObject>>;
    setIsClientError: Dispatch<SetStateAction<boolean>>;
    setRender: Dispatch<SetStateAction<boolean>>;
    loggerParameters: TggTraceCorrelationParameters;
};

export const useOptimizelyFeClient = (config: OptimizelyClientHookConfig) => {
    const [isClientLoaded, setIsClientLoaded] = useState(false);
    const [isClientInitialized, setIsClientInitialized] = useState(false);
    const [optimizelyFeClient, setClient] = useState<ReactSDKClient>();
    const [flagsLoaded, setFlagsLoaded] = useState(false);
    const {
        resolvedUserId,
        sdkKey,
        useClient,
        isUserInitialized,
        pathname,
        isRendered,
        setFlags,
        setIsClientError,
        setRender,
        loggerParameters,
    } = config;

    const {
        appConfig: { appLogger },
    } = useAppConfigContext();

    useEffect(() => {
        if (!isClientLoaded && isUserInitialized && useClient && !isRendered) {
            /* istanbul ignore next */
            if (
                optimizelyFeClient &&
                optimizelyFeClient.user.id === resolvedUserId
            ) {
                setIsClientLoaded(true);
            } else {
                initOptimizelyFeClient({
                    sdkKey,
                    flagsUserId: resolvedUserId,
                    successCallback: client => {
                        setClient(client);
                        setIsClientLoaded(true);
                    },
                    errorCallback: error => {
                        appLogger?.error(
                            `Optimizely client failed: ${
                                (error as Error).message
                            }`,
                            loggerParameters,
                        );
                        setFlags(defaultFlags);
                        setIsClientError(true);
                        setFlagsLoaded(true);
                        setRender(true);
                    },
                });
            }
        }
    }, [
        isClientLoaded,
        isRendered,
        sdkKey,
        resolvedUserId,
        useClient,
        isUserInitialized,
        optimizelyFeClient,
        setIsClientError,
        appLogger,
        setRender,
        setFlags,
        loggerParameters,
    ]);

    // decision GA event when using Fe client
    useEffect(() => {
        if (
            useClient &&
            isClientLoaded &&
            !isClientInitialized &&
            !isRendered &&
            optimizelyFeClient
        ) {
            attachDecisionEventListeners(optimizelyFeClient, {
                resolvedUserId,
                pathname,
                onDone: () => {
                    setIsClientInitialized(true);
                },
            });
        }
    }, [
        resolvedUserId,
        pathname,
        useClient,
        optimizelyFeClient,
        isClientInitialized,
        isClientLoaded,
        isRendered,
    ]);

    // get feature flags client side when using fe client
    useEffect(() => {
        if (
            !flagsLoaded &&
            isClientInitialized &&
            optimizelyFeClient &&
            useClient &&
            !isRendered
        ) {
            const flagsResult = optimizelyFeClient.decideAll(
                undefined,
                undefined,
                {
                    pathname,
                },
            );
            /* istanbul ignore else */
            if (flagsResult) {
                setFlags({
                    ...defaultFlags,
                    ...mapOptimizelyFlags(
                        flagsResult as unknown as Record<
                            OptimizelyFeatureFlags,
                            OptimizelyDecision
                        >,
                    ),
                });
            }
            setFlagsLoaded(true);
            setRender(true);
        }
    }, [
        flagsLoaded,
        isClientInitialized,
        optimizelyFeClient,
        pathname,
        useClient,
        isRendered,
        setFlags,
        setRender,
    ]);

    const trackFlagsEvent = useCallback(
        (
            optimizelyEvent: OptimizelyEvent,
            attributes?: CustomOptimizelyEventAttributes,
        ) => {
            if (useClient && optimizelyFeClient) {
                optimizelyFeClient.track(
                    optimizelyEvent,
                    undefined,
                    { pathname },
                    attributes ?? {},
                );
            }
        },
        [optimizelyFeClient, useClient, pathname],
    );

    return {
        trackFlagsEvent,
    };
};
