export interface WithDataTestId {
    'data-testid'?: string;
}

export const lowerEnvironments = [
    'local',
    'sit',
    'pat',
    'test',
    'dev',
    'mock-app-environment',
] as const;

export const produstionEnvironments = ['prod', 'production'] as const;
