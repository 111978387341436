import { IncomingMessage } from 'http';

/**
 * Checks the user agent to see if a request has come from the egym mobile app.
 * @param request NextJS request
 */
export function isEgymAppRequest(request: IncomingMessage): boolean {
    return request.headers['user-agent']?.includes('MobileApp') ?? false;
}

/**
 * Checks the user agent to see if the client is the egym mobile app.
 */
export function isEgymAppClient(): boolean {
    return window?.navigator?.userAgent?.includes('MobileApp') ?? false;
}

/**
 * checks if an incoming request has the headers we need to do egym token vlidation
 * @param request nextjs Request
 * @returns true if correct tokens exist in headers, false otherwise
 */
export function egymAppTokensExist(request: IncomingMessage): boolean {
    return !!(
        request.headers['x-tgg-accesstoken'] &&
        request.headers['x-tgg-accesstoken'].length > 0 &&
        request.headers['x-tgg-authtoken'] &&
        request.headers['x-tgg-authtoken'].length > 0
    );
}
