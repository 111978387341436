import { palette } from '../../../theme';
import { Heading } from '../../Heading';
import { Icon } from '../../Icon';
import { PaymentItemList } from '../../Payments/PaymentTile/PaymentItemList';
import { Tile } from '../../Tile';
import {
    StyledHeading,
    StyledBody,
    StyledLink,
    StyledTotalCostWrapper,
    StyledTotalCostText,
    StyledContainer,
} from './GymBagTile.styled';
import { GymBagTileProperties } from './GymBagTile.types';
import { decimalToPoundsAndPence } from '@tgg/util';

export const GymBagTile = ({
    title,
    items,
    total,
    gymPageUrl,
    onClose,
}: GymBagTileProperties) => {
    const showPaymentItems = items.length > 0;
    return (
        <StyledContainer>
            <Tile gutterBottom={false} variant="borderBlue">
                <StyledHeading>
                    <Heading
                        color={palette.common.white}
                        variant="h4"
                        gutterBottom={false}
                    >
                        <StyledLink href={gymPageUrl} onClick={onClose}>
                            {title}
                            <Icon
                                name="smallChevron"
                                size={20}
                                color={palette.common.white}
                            />
                        </StyledLink>
                    </Heading>
                </StyledHeading>
                <StyledBody>
                    {showPaymentItems && (
                        <PaymentItemList
                            items={items}
                            title={title}
                            hasDiscountPriceInRed={false}
                            hasSavingsItem={false}
                        />
                    )}
                </StyledBody>
                {!!total && (
                    <StyledTotalCostWrapper>
                        <StyledTotalCostText>
                            payment due today
                        </StyledTotalCostText>
                        <StyledTotalCostText data-testid={`${title} total`}>
                            {decimalToPoundsAndPence(total)}
                        </StyledTotalCostText>
                    </StyledTotalCostWrapper>
                )}
            </Tile>
        </StyledContainer>
    );
};
