export enum MembershipTypeText {
    LIVE_IT = 'LIVE IT',
    DO_IT = 'DO IT',
    STAFF = 'STAFF',
    STUDENT = 'STUDENT',
    DAY_PASS = 'DAY PASS',
    LIVE_IT_FIXED = 'LIVE IT FIXED',
    ULTIMATE = 'ULTIMATE',
    STANDARD = 'STANDARD',
    OFF_PEAK = 'OFF PEAK',
    GUEST_PASS = 'GUEST PASS',
}
