import { palette } from '../../theme';
import { Icon } from '../Icon';
import { StyledSnackBar, StyledHeading } from './SnackBar.styled';
import { SnackBarProperties } from './SnackBar.types';

/**
 * Use the `SnackBar` component to inform the user of any important information, promotion or status on user's actions on any tasks within our site.
 * The is shown at the top of the page underneath the sticky header.
 */

export function SnackBar({
    id,
    message,
    barType,
    iconName,
    className,
}: SnackBarProperties) {
    const barTypeIconColor = (type: string) => {
        return type === 'success' || type === 'error'
            ? palette.common.white
            : palette.common.blue;
    };

    const mapOfColors = {
        confirmation: {
            background: palette.secondary.main,
            textColor: palette.common.blue,
        },
        success: {
            background: palette.success.main,
            textColor: palette.common.white,
        },
        info: {
            background: palette.grey[50],
            textColor: palette.common.blue,
        },
        promo: {
            background: palette.secondary.main,
            textColor: palette.common.blue,
        },
        error: {
            background: palette.error.main,
            textColor: palette.common.white,
        },
    };

    return (
        <StyledSnackBar
            id={id}
            $barType={barType}
            data-testid={`barType-${barType}`}
            $colors={mapOfColors[barType]}
            className={className}
        >
            {iconName && (
                <Icon name={iconName} color={barTypeIconColor(barType)} />
            )}
            <StyledHeading
                variant="h2"
                gutterBottom={false}
                $barType={barType}
                $colors={mapOfColors[barType]}
            >
                {message}
            </StyledHeading>
        </StyledSnackBar>
    );
}

export default SnackBar;
